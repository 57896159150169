import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

import { Paths } from './paths-enum';
import { EnterNewPasswordType } from '../constants/enter-new-password-type';
import { Layout } from '../components/layout';
import { MainLayout } from '../components/main-layout';
import { ProtectedRoute } from '../components/protected-route';

const AuthPage = lazy(() => import('../pages/auth-page').then((m) => ({ default: m.AuthPage })));
const ChatPage = lazy(() => import('../pages/chat-page').then((m) => ({ default: m.ChatPage })));
const CreatePasswordPage = lazy(() =>
  import('../pages/create-password-page').then((m) => ({
    default: m.CreatePasswordPage,
  })),
);
const DuplicatePhoneNumber = lazy(() =>
  import('../pages/duplicate-phone-number').then((m) => ({
    default: m.DuplicatePhoneNumber,
  })),
);
const IdPage = lazy(() =>
  import('../pages/id-page').then((m) => ({
    default: m.IdPage,
  })),
);
const INECardPage = lazy(() =>
  import('../pages/ine-card-page').then((m) => ({
    default: m.INECardPage,
  })),
);
const IntroScreenPage = lazy(() =>
  import('../pages/intro-screen-page').then((m) => ({
    default: m.IntroScreenPage,
  })),
);
const AdditionalDataScreenPage = lazy(() =>
  import('../pages/additional-data-screen-page').then((m) => ({
    default: m.AdditionalDataScreenPage,
  })),
);
const UnderwritingCamera = lazy(() =>
  import('../pages/additional-data-screen-page/underwriting-camera').then((m) => ({
    default: m.UnderwritingCamera,
  })),
);
const LoanApplicationPage = lazy(() =>
  import('../pages/loan-application-page').then((m) => ({
    default: m.LoanApplicationPage,
  })),
);
const NotFoundPage = lazy(() =>
  import('../pages/not-found-page').then((m) => ({
    default: m.NotFoundPage,
  })),
);
const ProfilePage = lazy(() =>
  import('../pages/profile-page').then((m) => ({
    default: m.ProfilePage,
  })),
);
const RegistrationPage = lazy(() =>
  import('../pages/registration-page').then((m) => ({
    default: m.RegistrationPage,
  })),
);
const RequestStatusPage = lazy(() =>
  import('../pages/request-status-page').then((m) => ({
    default: m.RequestStatusPage,
  })),
);
const ResultPage = lazy(() =>
  import('../pages/result-page').then((m) => ({
    default: m.ResultPage,
  })),
);
const YourApplicationPage = lazy(() =>
  import('../pages/your-application-page').then((m) => ({
    default: m.YourApplicationPage,
  })),
);
const PDFViewerPage = lazy(() =>
  import('../pages/pdf-viewer-page').then((m) => ({
    default: m.PDFViewerPage,
  })),
);
const ApplicationPage = lazy(() =>
  import('../pages/application-page').then((m) => ({
    default: m.ApplicationPage,
  })),
);
const LoanAppDetailsPage = lazy(() =>
  import('../pages/loan-app-details-page').then((m) => ({
    default: m.LoanAppDetailsPage,
  })),
);
const JumioPage = lazy(() =>
  import('../pages/jumio-page').then((m) => ({
    default: m.JumioPage,
  })),
);
const DestinationAccountPage = lazy(() =>
  import('../pages/destination-account-page').then((m) => ({
    default: m.DestinationAccountPage,
  })),
);
const MainPage = lazy(() =>
  import('../pages/main-page').then((m) => ({
    default: m.MainPage,
  })),
);
const PersonalDataPage = lazy(() =>
  import('../pages/personal-data-page').then((m) => ({
    default: m.PersonalDataPage,
  })),
);
const BiometricPage = lazy(() =>
  import('../pages/biometric-page').then((m) => ({
    default: m.BiometricPage,
  })),
);
const ProcessingAppPage = lazy(() =>
  import('../pages/processing-app-page').then((m) => ({
    default: m.ProcessingAppPage,
  })),
);
// const ProtectedRoute = lazy(() =>
//   import('../components/protected-route').then((m) => ({
//     default: m.ProtectedRoute,
//   })),
// );
const PersonalInfoPage = lazy(() =>
  import('../pages/personal-info-page').then((m) => ({
    default: m.PersonalInfoPage,
  })),
);
const ContactDataPage = lazy(() =>
  import('../pages/contact-data-page').then((m) => ({
    default: m.ContactDataPage,
  })),
);
const SettingsPage = lazy(() =>
  import('../pages/settings-page').then((m) => ({
    default: m.SettingsPage,
  })),
);
const ChangePasswordPage = lazy(() =>
  import('../pages/change-password-page').then((m) => ({
    default: m.ChangePasswordPage,
  })),
);
const EmailConfirmationPage = lazy(() =>
  import('../pages/email-confirmation-page').then((m) => ({
    default: m.EmailConfirmationPage,
  })),
);
const LoanPaymentPage = lazy(() =>
  import('../pages/loan-payment-page').then((m) => ({
    default: m.LoanPaymentPage,
  })),
);
const PinPage = lazy(() =>
  import('../pages/pin-page').then((m) => ({
    default: m.PinPage,
  })),
);
const PasswordRecoveryPage = lazy(() =>
  import('../pages/password-recovery-page').then((m) => ({
    default: m.PasswordRecoveryPage,
  })),
);
const PaymentProviderPage = lazy(() =>
  import('../pages/payment-provider-page').then((m) => ({
    default: m.PaymentProviderPage,
  })),
);

export const routes = (
  <Routes>
    <Route path='/' element={<Layout />}>
      <Route index={true} element={<MainPage />} />
      <Route path={Paths.REGISTRATION} element={<RegistrationPage />} />
      <Route path={Paths.AUTH} element={<AuthPage />} />
      <Route path={Paths.PUSH_MAIN} element={<AuthPage />} />
      <Route path={Paths.PASSWORD_RECOVERY} element={<PasswordRecoveryPage />} />
      <Route
        path={Paths.NEW_PASSWORD}
        element={<CreatePasswordPage enter={EnterNewPasswordType.recoveryPassword} />}
      />
      <Route
        path={Paths.CREATE_PASSWORD}
        element={<CreatePasswordPage enter={EnterNewPasswordType.createPassword} />}
      />
      <Route path={Paths.RESULT_PAGE} element={<ResultPage />} />
      <Route path={Paths.DUPLICATE_PHONE_NUMBER} element={<DuplicatePhoneNumber />} />
      <Route path={Paths.REQUEST_STATUS} element={<RequestStatusPage />} />
      <Route path={Paths.CONFIRM_EMAIL} element={<EmailConfirmationPage />} />
      <Route path={Paths.PDF_VIEWER} element={<PDFViewerPage />} />
      <Route element={<ProtectedRoute />}>
        <Route path={Paths.JUMIO} element={<JumioPage />} />
        <Route path={Paths.ID_INFO} element={<IdPage />} />
        <Route path={Paths.ID_INFO_ADDRESS} element={<IdPage />} />
        <Route path={Paths.SCAN_INE_CARD} element={<INECardPage />} />
        <Route path={Paths.PERSONAL_INFO} element={<PersonalInfoPage />} />
        <Route path={Paths.PERSONAL_INFO_ADDRESS} element={<PersonalInfoPage />} />
        <Route path={Paths.LOAN_APPLICATION} element={<LoanApplicationPage />} />
        <Route path={Paths.INTRO_SCREEN} element={<IntroScreenPage />} />
        <Route path={Paths.ADDITIONAL_DATA_SCREEN_PAGE} element={<AdditionalDataScreenPage />} />
        <Route path={Paths.UNDERWRITING_CAM} element={<UnderwritingCamera />} />
        <Route path={Paths.PROCESSING_APP} element={<ProcessingAppPage />} />
        <Route path={Paths.APPLICATION_APPROVED} element={<YourApplicationPage />} />
        <Route path={Paths.DESTINATION_ACCOUNT} element={<DestinationAccountPage />} />
        <Route path={Paths.BIOMETRIC} element={<BiometricPage />} />
        <Route path={Paths.PIN} element={<PinPage />} />
        <Route path={Paths.LOAN_REPAY_PROVIDER} element={<PaymentProviderPage />} />
        <Route element={<MainLayout />}>
          <Route path={Paths.HOME} element={<MainPage />} />
          <Route path={Paths.HISTORY} element={<ApplicationPage />} />
          <Route path={Paths.CHAT} element={<ChatPage />} />
          <Route path={Paths.PROFILE} element={<ProfilePage />} />
          <Route path={Paths.PERSONAL_DATA} element={<PersonalDataPage />} />
          <Route path={Paths.CONTACT_DATA} element={<ContactDataPage />} />
          <Route path={Paths.SETTINGS} element={<SettingsPage />} />
          <Route path={Paths.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
          <Route path={Paths.LOAN_DETAILS} element={<LoanAppDetailsPage />} />
          <Route path={Paths.LOAN_PAYMENT} element={<LoanPaymentPage />} />
        </Route>
      </Route>
    </Route>
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);
