import { appUrl, env } from '../constants/app-url';

enum ApiUrl {
  DEV = 'https://api.dev.monetech.mx',
  TEST = 'https://api.test.dev.monetech.mx',
  PROD = 'https://api.monetech.mx',
  PROD2 = 'https://api.monetechservicios.com',
  PREPROD = 'https://api.preprod.monetech.mx',
}

const createApiObj = (apiUrl: ApiUrl) => ({
  REACT_APP_BASE_URL: `${apiUrl}/`,
  REACT_APP_KEYCLOAK_URL: `${apiUrl}/`,
  REACT_APP_MOCK_KEYCLOAK_URL: 'https://mock.dev.monetech.mx/api/',
  REACT_APP_BASE_LOAN_URL: `${apiUrl}/loan-origination/`,
  REACT_APP_BASE_USER_URL: `${apiUrl}/user-management/`,
  REACT_APP_BASE_NUBARIUM_URL: `${apiUrl}/nubarium-handler/`,
  REACT_APP_BASE_LOAN_DETAIL_URL: `${apiUrl}/loan-management/`,
});

export const getEnvUrl = () => {
  const location = window.location.href;

  let currentEnv = '';
  let apiObject = createApiObj(ApiUrl.TEST);

  if (location.includes(appUrl.DEV)) {
    currentEnv = env.DEV;
    apiObject = createApiObj(ApiUrl.DEV);
  }
  if (location.includes(appUrl.TEST)) {
    currentEnv = env.TEST;
    apiObject = createApiObj(ApiUrl.TEST);
  }
  if (location.includes(appUrl.LOCAL)) {
    currentEnv = env.LOCAL;
    apiObject = createApiObj(ApiUrl.DEV);
  }
  if (location.includes(appUrl.PROD)) {
    currentEnv = env.PROD;
    apiObject = createApiObj(ApiUrl.PROD);
  }
  if (location.includes(appUrl.PREPROD)) {
    currentEnv = env.PREPROD;
    apiObject = createApiObj(ApiUrl.PREPROD);
  }
  if (location.includes(appUrl.LOCAL_MOBILE)) {
    currentEnv = env.LOCAL_MOBILE;
    apiObject = createApiObj(ApiUrl.DEV);
  }
  if (location.includes(appUrl.PROD2)) {
    currentEnv = env.PROD;
    apiObject = createApiObj(ApiUrl.PROD2);
  }

  return {
    currentEnv,
    apiObject,
  };
};
