enum BaseUrl {
  AUTH = 'authorization',
  LOAN = 'loan',
  USER = 'user',
  REGISTRATION = 'registration',
  DIRECTORY = 'directory',
  APPLICATION = 'application',
  JUMIO = 'jumio-handler',
  JUMIO_MOCK = 'jumio-mock',
  RECOVERY = 'recovery',
  PUSH_NOTIFICATION_API = 'push-notification-api',
  UNDERWRITING = 'underwriting',
}

export enum ApiEndpoints {
  LOGIN = `${BaseUrl.AUTH}/auth/login`,
  LOGOUT = `${BaseUrl.AUTH}/auth/logout`,
  CHECK_PASSWORD = `${BaseUrl.AUTH}/auth/check-password`,
  REGISTRATION = `${BaseUrl.AUTH}/registration`,
  CREATE_PASSWORD = `${BaseUrl.AUTH}/registration/password`,
  RESEND_OTP = `${BaseUrl.AUTH}/${BaseUrl.REGISTRATION}/resend-otp`,
  CHECK_OTP = `${BaseUrl.AUTH}/${BaseUrl.REGISTRATION}/check-otp`,
  REFRESH_TOKEN = `${BaseUrl.AUTH}/auth/refresh-token`,
  CALCULATOR = '/calculator',
  PARAMETERS = '/application/parameters',
  LOAN_AGREEMENTS = `${BaseUrl.USER}/agreements`,
  OCCUPATIONS = `${BaseUrl.DIRECTORY}/occupations`,
  STATES_MUNICIPALITIES = `${BaseUrl.DIRECTORY}/states-and-municipalities`,
  CLIENT_DATA = '/client-data',
  CLIENT_DATA_MOCK = '/client-data-mock',
  PERSONAL_DATA = `${BaseUrl.USER}/personal-data`,
  CONTACT_DATA = `${BaseUrl.USER}/contact-data`,
  CHANGE_PASSWORD = `${BaseUrl.USER}/new-password/otp`,
  CHANGE_PASSWORD_RESEND_OTP = `${BaseUrl.USER}/new-password/resend-otp`,
  CHANGE_PASSWORD_CHECK_OTP = `${BaseUrl.USER}/new-password/check-otp`,
  CONFIRM_EMAIL = `${BaseUrl.USER}/confirm-email`,
  NUBARIUM_TOKEN = '/token',
  IMAGE_IDE = '/image-id',
  APPLICATION = `/${BaseUrl.APPLICATION}`,
  APPLICATION_ACCEPT = `/${BaseUrl.APPLICATION}/accept`,
  APPLICATION_OTP = `/${BaseUrl.APPLICATION}/otp`,
  APPLICATION_DECLINE = `/${BaseUrl.APPLICATION}/decline`,
  APPLICATION_CANCEL = `/${BaseUrl.APPLICATION}/cancel`,
  APPLICATION_CONFIRM = `/${BaseUrl.APPLICATION}/confirm`,
  APPLICATION_CONFIRM_RESEND_OTP = `/${BaseUrl.APPLICATION}/confirm/resend-otp`,
  APPLICATION_CONFIRM_CHECK_OTP = `/${BaseUrl.APPLICATION}/confirm/check-otp`,
  APPLICATION_RESEND_OTP = `/${BaseUrl.APPLICATION}/resend-otp`,
  APPLICATION_CHECK_OTP = `/${BaseUrl.APPLICATION}/check-otp`,
  APPLICATION_STATUS = `/${BaseUrl.APPLICATION}/status`,
  APPLICATION_PAYMENT_TOOL = `/${BaseUrl.APPLICATION}/payment-tool`,
  IMAGE_IDE_MOCK = '/image-id-mock',
  IMAGE_FACE = '/image-face',
  IMAGE_FACE_MOCK = '/image-face-mock',
  HISTORY = '/history',
  JUMIO_TOKEN = `${BaseUrl.JUMIO}/token`,
  JUMIO_CLIENT_DATA = `${BaseUrl.JUMIO}/client-data`,
  JUMIO_TOKEN_MOCK = `${BaseUrl.JUMIO}/${BaseUrl.JUMIO_MOCK}/token`,
  JUMIO_CLIENT_DATA_MOCK = `${BaseUrl.JUMIO}/${BaseUrl.JUMIO_MOCK}/client-data`,
  PARTNERS = '/partners',
  LOAN = '/loan',
  LOAN_REPAYMENT = '/loan/repayment',
  RECOVERY_CHECK_PHONE = `${BaseUrl.AUTH}/${BaseUrl.RECOVERY}/check-phone`,
  RECOVERY_OTP = `${BaseUrl.AUTH}/${BaseUrl.RECOVERY}/otp`,
  RECOVERY_RESEND_OTP = `${BaseUrl.AUTH}/${BaseUrl.RECOVERY}/resend-otp`,
  RECOVERY_CHECK_OTP = `${BaseUrl.AUTH}/${BaseUrl.RECOVERY}/check-otp`,
  RECOVERY_NEW_PASSWORD = `${BaseUrl.AUTH}/${BaseUrl.RECOVERY}/new-password`,
  CHAT_DATA = `${BaseUrl.USER}/chat-data`,
  DELETE_USER = `${BaseUrl.USER}/delete-request`,
  UNSUBSCRIBE = `${BaseUrl.USER}/unsubscribe-marketing`,
  PUSH_TOKEN = `${BaseUrl.PUSH_NOTIFICATION_API}/push-token`,
  OPERATIONS = `${BaseUrl.UNDERWRITING}/operations`,
  UNDERWRITING_DEVICE_DETAILS = `${BaseUrl.UNDERWRITING}/device-details`,
  UNDERWRITING_IMAGE_FRONT_ID = `${BaseUrl.UNDERWRITING}/image-front-id`,
  UNDERWRITING_IMAGE_BACK_ID = `${BaseUrl.UNDERWRITING}/image-back-id`,
  UNDERWRITING_IMAGE_FACE = `${BaseUrl.UNDERWRITING}/image-face`,
  UNDERWRITING_PROOF_OF_ADDRESS = `${BaseUrl.UNDERWRITING}/proof-of-address`,
}
