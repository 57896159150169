import platform from 'platform';

const isAndroidApp = window.Android && typeof window.Android.monetechGetNativeInfo === 'function';

const isIOSApp =
  window.webkit &&
  window.webkit.messageHandlers &&
  typeof window.webkit.messageHandlers.monetechGetNativeInfo === 'function';

export const isWebContext = !isAndroidApp && !isIOSApp;

const defaultOsFamily = platform.os?.family;
let osFamily = null;

if (defaultOsFamily === 'Android' || defaultOsFamily === 'iOS') {
  osFamily = 'MOBILE';
} else if (defaultOsFamily !== null) {
  osFamily = 'WEB';
}

export const isWebContextPlatform = osFamily === 'WEB';
