import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Role } from '../../constants/role';
import { AlertStatuses } from '../../types/general';
import { ResultInfo } from '../../types/result-info-type';
import { isArrayWithItems } from '../../utils/is-array-with-items';
import { ApplicationState } from '../configure-store';
import { PdfViewerType } from '../../types/pdf-viewer-type';
import { InputError, InputWarnings } from '../../types/user';
import { DeviceInfo } from '../../hooks/use-get-native-info';
import { Operation } from '../../types/underwriting-handler';

export type AppState = typeof initialState;

export const initialState = {
  error: '',
  userRoles: [] as Role[],
  isLoading: false,
  alerts: [] as AlertStatuses[],
  timeResend: 0,
  requestStatus: '',
  isNubariumImitation: false,
  isJumioImitation: false,
  fileName: '',
  jumioToken: '',
  applicationId: '',
  operationId: '',
  resultInfo: {
    status: '',
    title: '',
    description: '',
    buttons: [],
  } as ResultInfo,
  pdfViewer: {
    url: '',
    paymentSchedule: '',
    loanAgreement: '',
    title: '',
  } as PdfViewerType,
  phoneNumber: '',
  nextStep: '',
  pushNextStep: '',
  inputError: {} as InputError,
  inputWarnings: {} as InputWarnings,
  lang: 'es',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pinHandler: (token: string) => {},
  deviceNativeInfo: {} as DeviceInfo,
  isCancelApplication: false,
  pushToken: '',
  versionGplayReview: '1.1.29 gplay',
  operation: [] as Operation[],
  isOpenGeoModal: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppError(state, { payload: error }: PayloadAction<string>) {
      state.error = error;
    },
    setUserRoles(state, { payload: userRoles }: PayloadAction<Role[]>) {
      state.userRoles = userRoles;
    },
    setAppLoader(state, { payload: isLoading }: PayloadAction<boolean>) {
      state.isLoading = isLoading;
    },
    setPopupAlert(state, { payload }: PayloadAction<AlertStatuses>) {
      state.alerts.push(payload);
    },
    deleteFirstPopupAlert(state) {
      state.alerts.shift();
    },
    setTimeResend(state, { payload: timeLeft }: PayloadAction<number>) {
      state.timeResend = timeLeft;
    },
    setRequestStatus(state, { payload: status }: PayloadAction<string>) {
      state.requestStatus = status;
    },
    setLang(state, { payload: lang }: PayloadAction<string>) {
      state.lang = lang;
    },
    setIsNubariumImitation(state, { payload: status }: PayloadAction<boolean>) {
      state.isNubariumImitation = status;
    },
    setIsJumioImitation(state, { payload: status }: PayloadAction<boolean>) {
      state.isJumioImitation = status;
    },
    setFileName(state, { payload: fileName }: PayloadAction<string>) {
      state.fileName = fileName;
    },
    setJumioToken(state, { payload: token }: PayloadAction<string>) {
      state.jumioToken = token;
    },
    setApplicationId(state, { payload: id }: PayloadAction<string>) {
      state.applicationId = id;
    },
    setOperationId(state, { payload: id }: PayloadAction<string>) {
      state.operationId = id;
    },
    setNextStep(state, { payload: nextStep }: PayloadAction<string>) {
      state.nextStep = nextStep;
    },
    setPushNextStep(state, { payload: nextStep }: PayloadAction<string>) {
      state.pushNextStep = nextStep;
    },
    setResultInfo(state, { payload: resultInfo }: PayloadAction<ResultInfo>) {
      state.resultInfo = resultInfo;
    },
    setInputError(state, { payload: inputError }: PayloadAction<InputError>) {
      state.inputError = inputError;
    },
    setInputWarnings(state, { payload: inputWarnings }: PayloadAction<InputWarnings>) {
      state.inputWarnings = inputWarnings;
    },
    setPdfViewer(state, { payload: pdfViewer }: PayloadAction<PdfViewerType>) {
      state.pdfViewer = pdfViewer;
    },
    setPhoneNumber(state, { payload: value }: PayloadAction<string>) {
      state.phoneNumber = value;
    },
    clearStateOnLogout: () => initialState,
    setDeviceNativeInfo: (state, action) => {
      state.deviceNativeInfo = action.payload;
    },
    setReduxPinHandler: (state, action: PayloadAction<(token: string) => void>) => {
      state.pinHandler = action.payload;
    },
    setIsCancelApplication(state, { payload: isCancelApplication }: PayloadAction<boolean>) {
      state.isCancelApplication = isCancelApplication;
    },
    setPushToken(state, { payload: token }: PayloadAction<string>) {
      state.pushToken = token;
    },
    setOperation(state, { payload: operation }) {
      state.operation = operation;
    },
    setIsOpenGeoModal(state, { payload }: PayloadAction<boolean>) {
      state.isOpenGeoModal = payload;
    },
  },
});

export const appSelector = (state: ApplicationState) => state.app;
export const prevLocationsSelector = (state: ApplicationState) => state.router.previousLocations;
export const errorSelector = (state: ApplicationState) => state.app?.error;
export const timeLeftSelector = (state: ApplicationState) => state.app.timeResend;
export const requestStatusSelector = (state: ApplicationState) => state.app.requestStatus;
export const isNubariumImitationSelector = (state: ApplicationState) =>
  state.app.isNubariumImitation;
export const isJumioImitationSelector = (state: ApplicationState) => state.app.isJumioImitation;
export const fileNameSelector = (state: ApplicationState) => state.app.fileName;
export const jumioTokenSelector = (state: ApplicationState) => state.app.jumioToken;
export const isCancelApplicationSelector = (state: ApplicationState) =>
  state.app.isCancelApplication;
export const popupAlertSelector = (state: ApplicationState) =>
  isArrayWithItems(state.app.alerts) ? state.app.alerts.at(0) : null;
export const applicationIdSelector = (state: ApplicationState) => state.app.applicationId;
export const langSelector = (state: ApplicationState) => state.app.lang;
export const operationIdSelector = (state: ApplicationState) => state.app.operationId;
export const nextStepSelector = (state: ApplicationState) => state.app.nextStep;
export const pushNextStepSelector = (state: ApplicationState) => state.app.pushNextStep;
export const resultInfoSelector = (state: ApplicationState) => state.app.resultInfo;
export const inputErrorSelector = (state: ApplicationState) => state.app.inputError;
export const inputWarningsSelector = (state: ApplicationState) => state.app.inputWarnings;
export const pdfViewerSelector = (state: ApplicationState) => state.app.pdfViewer;
export const phoneNumberSelector = (state: ApplicationState) => state.app.phoneNumber;
export const pinHandlerSelector = (state: ApplicationState): ((str: string) => void) =>
  state.app.pinHandler;
export const deviceNativeInfoSelector = (state: ApplicationState) => state.app.deviceNativeInfo;
export const pushTokenSelector = (state: ApplicationState) => state.app.pushToken;
export const versionGplayReviewSelector = (state: ApplicationState) => state.app.versionGplayReview;
export const operationSelector = (state: ApplicationState) => state.app.operation;
export const {
  setAppError,
  clearStateOnLogout,
  setUserRoles,
  setAppLoader,
  setPopupAlert,
  deleteFirstPopupAlert,
  setTimeResend,
  setRequestStatus,
  setIsNubariumImitation,
  setIsJumioImitation,
  setFileName,
  setApplicationId,
  setResultInfo,
  setPdfViewer,
  setPhoneNumber,
  setOperationId,
  setNextStep,
  setPushNextStep,
  setInputError,
  setInputWarnings,
  setLang,
  setReduxPinHandler,
  setDeviceNativeInfo,
  setIsCancelApplication,
  setPushToken,
  setJumioToken,
  setOperation,
  setIsOpenGeoModal,
} = appSlice.actions;

export default appSlice.reducer;
