// TODO: delete
export const appUrl = {
  DEV: 'pwa.dev.monetech.mx',
  TEST: 'pwa.test.dev.monetech.mx',
  PROD: 'pwa.monetech.mx',
  PROD2: 'pwa.monetechservicios.com',
  PREPROD: 'pwa.preprod.monetech.mx',
  LOCAL: 'localhost:',
  LOCAL_MOBILE: '192.168.1.',
};

export const env = {
  DEV: 'dev',
  TEST: 'test',
  LOCAL: 'local',
  LOCAL_MOBILE: 'local-mobile',
  PROD: 'prod',
  PREPROD: 'preprod',
};
