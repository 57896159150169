export enum EndpointNames {
  LOGIN = 'login',
  REFRESH = 'refresh',
  CALCULATOR = 'calculator',
  AGREEMENTS = 'agreements',
  REGISTRATION = 'registration',
  CHECK_OTP = 'check-otp',
  OCCUPATIONS = 'occupations',
  STATES_MUNICIPALITIES = 'states-municipalities',
  CLIENT_DATA = 'client-data',
  PERSONAL_DATA = 'personal-data',
  CONTACT_DATA = 'contact-data',
  NUBARIUM = 'nubarium',
  APPLICATION = 'application',
  APPLICATION_ACCEPT = 'application-accept',
  APPLICATION_DECLINE = 'application-decline',
  APPLICATION_CANCEL = 'application-cancel',
  APPLICATION_CONFIRM = 'application-confirm',
  APPLICATION_STATUS = 'application-status',
  APPLICATION_PAYMENT_TOOL = 'application-payment-tool',
  HISTORY = 'history',
  JUMIO_TOKEN = 'jumio-token',
  JUMIO_CLIENT_DATA = 'jumio-client-data',
  CHANGE_PASSWORD = 'change-password',
  CONFIRM_EMAIL = 'confirm-email',
  PARTNERS = 'partners',
  RECOVERY = 'recovery',
  CHAT = 'chat',
  DELETE_USER = 'delete-user',
  UNSUBSCRIBE = 'unsubscribe',
  UNDERWRITING = 'underwriting',
}
