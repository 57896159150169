import { t } from 'i18next';

export enum ResultType {
  error = 'error',
  success = 'success',
  info = 'info',
  waiting = 'waiting',
  warning = 'warning',
}

export enum ResultTypeVariants {
  phoneNumber = 'phoneNumber',
  waiting = 'waiting',
  processingProblem = 'processingProblem',
  home = 'home',
}

export const RESULT_CONTENT = {
  [ResultType.waiting]: {
    title: t('result.congratulations'),
    description: t('result.referToYourAccount'),
  },
  [ResultType.error]: {
    title: t('result.error'),
    description: t('result.loanApplicationProcessInterruption'),
  },
  [ResultType.success]: {
    title: '',
    description: '',
  },
  [ResultType.info]: {
    title: '',
    description: '',
  },
  [ResultType.warning]: {
    title: '',
    description: '',
  },
};
