/* eslint-disable */
// @ts-nocheck

import { AppsflyerEventName } from '../types/appsflyer';
import { encryptClientId } from './encrypt-client-id';
import { LOCAL_STORAGE } from '../constants/local-storage';
import { isWebContext } from './is-web-context';
import { getEnvUrl } from './get-env-url';
import { env } from '../constants/app-url';
import uuid from 'react-uuid';

const WEB_DEV_KEY = '8ac7b57c-1256-483c-b655-258928c65d16';

export default class AppsFlyer {
  static initAppsflyer() {
    const { currentEnv } = getEnvUrl();

    if (![env.PREPROD, env.PROD].includes(currentEnv)) {
      return;
    }
    if (!isWebContext) {
      return;
    }
    return !(function (t, e, n, s, a, c, i, o, p) {
      (t.AppsFlyerSdkObject = a),
        (t.AF =
          t.AF ||
          function () {
            (t.AF.q = t.AF.q || []).push(
              [Date.now()].concat(Array.prototype.slice.call(arguments)),
            );
          }),
        (t.AF.id = t.AF.id || i),
        (t.AF.plugins = {}),
        (o = e.createElement(n)),
        (p = e.getElementsByTagName(n)[0]),
        (o.async = 1),
        (o.src =
          'https://websdk.appsflyer.com?' +
          (c.length > 0 ? 'st=' + c.split(',').sort().join(',') + '&' : '') +
          (i.length > 0 ? 'af_id=' + i : '')),
        p.parentNode.insertBefore(o, p);
    })(window, document, 'script', 0, 'AF', 'pba', { pba: { webAppId: WEB_DEV_KEY } });
  }

  static setCustomerUserId() {
    const { currentEnv } = getEnvUrl();
    const eqId = uuid();

    if (![env.PREPROD, env.PROD].includes(currentEnv)) {
      return;
    }
    if (typeof AF !== 'undefined') {
      sessionStorage.setItem(LOCAL_STORAGE.eqId, eqId);
      AF('pba', 'setCustomerUserId', eqId);
    }
  }

  static sendEvent(eventName: string, value?: object = {}) {
    const { currentEnv } = getEnvUrl();

    if (![env.PREPROD, env.PROD].includes(currentEnv)) {
      return;
    }

    let eventValue = {};
    const eqId = sessionStorage.getItem(LOCAL_STORAGE.eqId);
    if (eqId) {
      eventValue = { CUID: eqId };
    }
    if (Object.keys(value).length !== 0) {
      eventValue = { ...eventValue, ...value };
    }
    const isObjEventValue = Object.keys(eventValue).length !== 0;

    if (window?.Android && typeof window?.Android?.recordEvent === 'function') {
      return window.Android.recordEvent(
        eventName,
        isObjEventValue ? JSON.stringify(eventValue) : undefined,
      );
    }

    if (isWebContext && typeof AF !== 'undefined') {
      return AF('pba', 'event', {
        eventType: 'EVENT',
        eventName,
        ...(isObjEventValue && eventValue),
      });
    }

    if (window.gtag) {
      window.gtag('event', eventName, {
        ...eventValue,
      });
    } else if (window.ga) {
      window.ga('send', 'event', {
        eventCategory: 'AppsFlyer',
        eventAction: eventName,
        eventLabel: JSON.stringify(eventValue),
      });
    }
  }
}
