import { LOCAL_STORAGE } from '../constants/local-storage';

export const getUtmCampaign = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const setUtmLocalStorage = (utm: string, localStorageValue: string) => {
    if (urlParams.has(utm)) {
      const utmValue = urlParams.get(utm);

      if (utmValue && !localStorage.getItem(localStorageValue))
        localStorage.setItem(localStorageValue, utmValue);
    }
  };

  setUtmLocalStorage('fingerprint', LOCAL_STORAGE.xFingerprint);

  if (urlParams.has('utm_campaign')) {
    const utmCampaign = urlParams.get('utm_campaign');

    if (utmCampaign && !localStorage.getItem(LOCAL_STORAGE.refCode)) {
      localStorage.setItem(LOCAL_STORAGE.refCode, utmCampaign);
      localStorage.setItem(LOCAL_STORAGE.utm_campaign, utmCampaign);

      setUtmLocalStorage('utm_source', LOCAL_STORAGE.utm_source);
      setUtmLocalStorage('utm_medium', LOCAL_STORAGE.utm_medium);
      setUtmLocalStorage('utm_term', LOCAL_STORAGE.utm_term);
      setUtmLocalStorage('utm_content', LOCAL_STORAGE.utm_content);
      setUtmLocalStorage('gclid', LOCAL_STORAGE.gclid);
      setUtmLocalStorage('fbclid', LOCAL_STORAGE.fbclid);
      setUtmLocalStorage('ttclid', LOCAL_STORAGE.ttclid);
      setUtmLocalStorage('gaid', LOCAL_STORAGE.gaid);
    }
  }
};
