import { useCallback, useEffect, useRef } from 'react';
import { NoticeBar } from 'antd-mobile';
import { ExclamationCircleOutline } from 'antd-mobile-icons';

import { ALERT_DELETE_TIMEOUT, AlertMessage } from '../../constants/alert-message';
import { useAppDispatch, useAppSelector } from '../../hooks/use-redux-hook';
import {
  deleteFirstPopupAlert,
  phoneNumberSelector,
  popupAlertSelector,
  timeLeftSelector,
} from '../../redux/modules/app';
import { Statuses } from '../../types/general';
import { Portal } from '../portal';
import styles from './custom-notice-bar.module.css';

export const CustomNoticeBar = () => {
  const alert = useAppSelector(popupAlertSelector);
  const timeLeft = useAppSelector(timeLeftSelector);
  const phone = useAppSelector(phoneNumberSelector);
  const dispatch = useAppDispatch();
  const timerId = useRef<NodeJS.Timeout | null>(null);
  const isAlertHaveToAutoDelete =
    !!alert?.time ||
    alert?.type === Statuses.ERROR ||
    alert?.type === Statuses.INFO ||
    alert?.type === Statuses.WARNING;

  const handleDeletePopupAlert = useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
      timerId.current = null;
    }
    dispatch(deleteFirstPopupAlert());
  }, [dispatch]);

  useEffect(() => {
    if (isAlertHaveToAutoDelete) {
      timerId.current = setTimeout(() => {
        handleDeletePopupAlert();
      }, alert?.time ?? ALERT_DELETE_TIMEOUT);
    }
  }, [alert, handleDeletePopupAlert, isAlertHaveToAutoDelete]);

  const time = timeLeft > 60 ? `${Math.ceil(timeLeft / 60)} min` : `${timeLeft} sec`;

  const getColor = () => {
    switch (alert?.type) {
      case Statuses.ERROR: {
        return 'error';
      }
      case Statuses.WARNING: {
        return 'alert';
      }
      case Statuses.INFO: {
        return 'info';
      }
      case Statuses.SUCCESS: {
        return 'info';
      }
      default: {
        return 'default';
      }
    }
  };

  if (!alert) return null;

  const getContent = () => {
    switch (alert?.message) {
      case AlertMessage.ERROR_SMS_SEND_BLOCK:
        return `A new SMS code can be generated in ${time}`;
      case AlertMessage.SMS_CODE_SENT:
        return `SMS code sent to ${phone}`;
      default:
        return alert?.message;
    }
  };

  return (
    <Portal>
      <div className={styles.wrapper}>
        <NoticeBar
          className={styles.alert}
          content={getContent()}
          icon={<ExclamationCircleOutline />}
          color={getColor()}
          closeable={true}
          onClose={handleDeletePopupAlert}
        />
      </div>
    </Portal>
  );
};
