export const RANDOM_DEEP_LINK = Math.random() < 0.5 ? 'mvp_free_ab' : 'mvp_400_ab';
export const GGL_REF_CODE = 'ggl';
export const UTM_CAMPAIGN = {
  display_test: 'display_test',
  display_ab: 'display_ab',
};
export const REF_CODE = {
  mvp400: 'mvp_400',
  mvpFree: 'mvp_free',
};
